



































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import FormatMixin from '../../../mixin/format'
import {
	Product,
	ProductOptionQuantity,
	Price,
	ProductResource
} from '@api/retail'
import ImageGroup from '../image/group.vue'

Component.registerHooks([
	'beforeRouteUpdate'
])

@Component({
	props: ['id'],
	mixins: [FormatMixin],
	components: {
		'image-group': ImageGroup
	},
	data: function () {
		return {
			product: {
				id: null,
				prices: [],
				options: [],
				description: ""
			},
			quantityPrice: '',
			productOption: null,
			picked: "",
			loading: true
		}
	}
})
export default class ProductDetail extends Vue {
	id:number;
	product:Product;
	quantityPrice:Price;
	productOption:string;
	loading:boolean;
	productResource:ProductResource = new ProductResource();

	mounted() {
		this.product.id = this.id;
		this.load();
	}
	beforeRouteUpdate(to:any, from:any, next:any) {
		if (to.params && to.params.id != this.product.id) {
			this.product.id = to.params.id;
			this.load();
		}
		next();
	}
	load() {
		this.loading = true;
		this.productResource.findOne(this.product.id).then((product) => {
			this.product = product;
			this.selectFirstQuantity();
			this.selectFirstOptionIfOnlyOne();
			this.loading = false;
		}, function () {});
	}
	selectFirstQuantity() {
		this.quantityPrice = this.product.prices[0];
	}
	selectFirstOptionIfOnlyOne() {
		if (this.product.options && this.product.options.length == 1 && this.product.options[0].quantity > 0) {
			this.productOption = this.product.options[0].name;
		}
	}
	addToCart() {
		this.$appStore.commit('addItem', {
			productId: this.product.id,
			name: this.product.name,
			description: this.product.description,
			quantity: this.quantityPrice.quantity,
			fullPrice: this.quantityPrice.price,
			currentPrice: this.quantityPrice.discount ? this.quantityPrice.discount : this.quantityPrice.price,
			option: this.productOption,
			image: this.product.images[0]
		});
		this.$router.push({ path: '/cart' });
	}
	get addToCartDisabled() : boolean {
		return this.productOption == null;
	}
	get isOutOfStock() : boolean {
		return this.product.options.every(option => option.quantity == 0);
	}
	get formattedDescription() {
		var description = this.product.description.replace(/\n/g, "<br>")
		description = this.replaceLinks(description);
		return description;
	}
	replaceLinks(description:string):string {
		var descWithLinks = description;
		var indexOfNextLink;
		var linkCount = 0;
		while ((indexOfNextLink = descWithLinks.indexOf('<link=')) >= 0) {
			linkCount++;
			if (linkCount > 100) {
				return description;
			}
			var productIdStartIndex = indexOfNextLink + 6;
			var linkString = descWithLinks.substring(productIdStartIndex);
			var productIdEndIndex = linkString.indexOf(' ');
			var productId = linkString.substring(0, productIdEndIndex);
			var linkTextEndIndex = linkString.indexOf('>');
			var productText = linkString.substring(productIdEndIndex + 1, linkTextEndIndex);
			var replacementText = '<span class="product-link"><a href="#/products/' + productId + '">' + productText + '</a></span>';
			descWithLinks = descWithLinks.substring(0, indexOfNextLink) + replacementText + descWithLinks.substring(indexOfNextLink + linkTextEndIndex + 7);
		}
		return descWithLinks;
	}
}
