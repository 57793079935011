

















import Vue from 'vue'
import { ComponentOptions } from 'vue'
import Component from 'vue-class-component'
import VueAnalytics from 'vue-analytics'
import BootstrapVue from 'bootstrap-vue'
import Header from './header.vue'
import Footer from './footer.vue'
import Router from '../router/router'
import Store, { typedStore } from '../store/store'

Vue.use(BootstrapVue);
Vue.use(VueAnalytics, {
	id: 'UA-113858139-1',
	router: Router
})
Vue.use(typedStore);
@Component({
	store: Store,
	router: Router,
	beforeCreate: () => {
		Store.commit('initializeStore');
	},
	components: {
		'header-view': Header,
		'footer-view': Footer
	}
})
export default class Main extends Vue {
	get key() {
		return this.$route.meta?.key !== undefined ? this.$route.meta.key : 1
	}
}
