import { Resource } from '@api/core'
import { AxiosInstance } from 'axios'
import Product from '../model/Product'

export default class extends Resource<Product> {
	basePath = '/retail'
	resourceName = '/v1/products'

	constructor(client?: AxiosInstance) {
		super(client);
	}

	copy(id:number):Promise<Product> {
		return new Promise<Product>((resolve, reject) => {
			this.client.post<Product>(this.basePath + this.resourceName + "/" + id + '/copy', {})
				.then((response) => {
					resolve(response.data);
				}).catch((error:any) => {
					reject(error);
				});
		});
	}
}