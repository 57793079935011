import { Order } from "@api/retail";
import { PaypalOrder } from "@api/paypal";

export interface OrderState {
	order: Order;
	paypalOrder: PaypalOrder;
}

export default {
	state: {
		order: {},
		paypalOrder: {}
	},
	mutations: {
		setOrder(state:OrderState, order:Order) {
			if (order != undefined) {
				state.order = order;
				sessionStorage.order = JSON.stringify(state.order);
			} else if (window.sessionStorage && window.sessionStorage.order) {
				try {
					state.order = JSON.parse(window.sessionStorage.order);
				} catch (ex) {
					window.sessionStorage.order = JSON.stringify({});
				}
			}
		},
		setPaypalOrder(state:OrderState, paypalOrder:PaypalOrder) {
			if (paypalOrder) {
				state.paypalOrder = paypalOrder;
				sessionStorage.paypalOrder = JSON.stringify(state.paypalOrder);
			} else if (window.sessionStorage && window.sessionStorage.paypalOrder) {
				try {
					state.paypalOrder = JSON.parse(window.sessionStorage.paypalOrder);
				} catch (ex) {
					window.sessionStorage.paypalOrder = JSON.stringify({});
				}
			}
		}
	}
}