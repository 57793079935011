
































import Vue from 'vue'
import Component from 'vue-class-component'
import { User, UserResource } from '@api/user'

@Component({
	data: function () {
		return {
			email: null,
			showSubscribePopover: false
		}
	}
})
export default class EmailEntry extends Vue {
	email:string;
	userResource:UserResource = new UserResource();
	showSubscribePopover:boolean = false;

	subscribe() : void {
		const user = {
			email: this.email
		}
		this.userResource.create(user).then((u) => {
			this.email = "";
			this.showSubscribePopover = true;
			setTimeout(() => this.showSubscribePopover = false, 2000);
		}).catch((error) => {
			alert('unable to subscribe, try again later.')
		});
	}

	get validEmail(): boolean {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(this.email);
	}
}
