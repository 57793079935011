












































import Vue from 'vue'
import Component from 'vue-class-component'
import { Promotion, LinkImage, PromotionResource } from '@api/retail'
import { facebookLink, instagramLink, imageBaseUrl } from '../constants'
import ImageMixin from '../../mixin/image'

@Component({
	mixins: [ImageMixin]
})
export default class Home extends Vue {
	facebookLink = facebookLink;
	promotion: Promotion = {} as Promotion;
	promotionResource: PromotionResource = new PromotionResource();
	mounted() {
		this.promotionResource.findAll().then((result) => {
			if (result.results.length > 0) {
				this.promotion = result.results[0];
			}
		})
	}
	get promotionImages(): LinkImage[] {
		if (this.promotion.images?.length > 0) {
			return this.promotion.images;
		}
		return [{
			url: `${imageBaseUrl}/static/placeholder.jpg`,
			link: ''
		}];
	}
	get promotionDescription(): string {
		const value = this.promotion.description?.replace(/\n/g, '<br>')
		return value
	}
}
