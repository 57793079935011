import { Resource } from '@api/core'
import Category from '../model/Category'
import { AxiosInstance } from 'axios';

export default class extends Resource<Category> {
	basePath = '/retail'
	resourceName = '/v1/categories'

	constructor(client?: AxiosInstance) {
		super(client);
	}
}