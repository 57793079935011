import Vue from 'vue'
import Vuex from 'vuex'
import PaymentModule, { PaymentState } from './module/payment'
import CartModule, { CartState } from './module/cart'
import OrderModule, { OrderState } from './module/order';

Vue.use(Vuex);

export type StoreState = {cart: CartState} & {payment: PaymentState} & {order: OrderState};

const state: StoreState = {
	cart: {
		items: []
	},
	payment: {
		payment: {}
	},
	order: {
		order: { items: [], subtotal: 0, tax: 0, total: 0, shipping: 0 },
		paypalOrder: { id: '', purchase_units: [] }
	}
}

const store = new Vuex.Store<StoreState>({
	modules: {
		cart: CartModule,
		payment: PaymentModule,
		order: OrderModule
	},
	state: state,
	mutations: {
		initializeStore: (storeState:StoreState) => {
			const storeJson = sessionStorage.getItem('store');
			if (storeJson) {
				try {
					store.replaceState(
						Object.assign(storeState, JSON.parse(storeJson))
					);
				} catch (ex) {
					store.replaceState(
						Object.assign(storeState, state)
					);
				}
			}
		}
	}
});

store.subscribe((mutation, state) => {
	sessionStorage.setItem('store', JSON.stringify(state));
});

export function typedStore(vue: typeof Vue): void {
	vue.prototype.$appStore = store;
}


export default store;