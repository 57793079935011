import { Resource } from '@api/core'
import { AxiosInstance } from 'axios'
import Order from '../model/Order'
import OrderPricingRequest from '../model/OrderPricingRequest'
import OrderPricingResponse from '../model/OrderPricingResponse'

export default class extends Resource<Order> {
	basePath = '/retail'
	resourceName = '/v1/orders'

	constructor(client?: AxiosInstance) {
		super(client);
	}

	applyPricing(orderPricingRequest?:OrderPricingRequest):Promise<OrderPricingResponse> {
		return new Promise<OrderPricingResponse>((resolve, reject) => {
			this.client.post<OrderPricingResponse>(this.basePath + this.resourceName + '/pricing', orderPricingRequest)
				.then((response) => {
					resolve(response.data);
				}).catch((error:any) => {
					reject(error);
				});
		});
	}
}