


































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop } from 'vue-property-decorator'
import FormatMixin from '../../mixin/format'
import ImageMixin from '../../mixin/image'
import {
	Order,
	OrderResource,
	OrderPricingRequest,
	OrderPricingResponse,
	CartItem
} from '@api/retail'
import { PaypalOrder } from '@api/paypal'
import PaypalButton from '../vue/paypal/button.vue'

@Component({
	mixins: [FormatMixin, ImageMixin],
	components: {
		'paypal-button': PaypalButton
	}
})
export default class Cart extends Vue {
	discountCode: string = "";
	coloradoResident: boolean = true;
	applyingPricing: boolean = true;
	order:Order = { items: [], subtotal: 0, total: 0, tax: 0, shipping: 0 };
	orderResource:OrderResource = new OrderResource();

	mounted() {
		setTimeout(this.applyPricing, 500);
	}
	@Watch('coloradoResident')
	onChildChanged(val: boolean, oldVal: boolean) {
		this.applyPricing();
	}
	orderComplete(order:Order, paypalOrder:PaypalOrder): void {
		this.$appStore.commit('setOrder', order);
		this.$appStore.commit('setPaypalOrder', paypalOrder);
		this.order = order;
		this.order.payment = paypalOrder;
		this.$appStore.commit('setItems', []);
		this.saveOrder();
		this.$router.push({ path: '/sale/complete' });
	}
	applyPricing() : void {
		this.order.items = this.items;
		const orderPricingRequest = {
			order: this.order,
			discountCode: this.discountCode,
			coloradoResident: this.coloradoResident
		};
		this.applyingPricing = true;
		if (this.order.items.length > 0) {
			this.orderResource.applyPricing(orderPricingRequest).then((pricingResponse) => {
				this.applyingPricing = false;
				this.order = pricingResponse.order;
				this.$appStore.commit('setItems', pricingResponse.order.items);
			}).catch((error:any) => {
				this.applyingPricing = false;
			});
		}
	}
	saveOrder() {
		this.orderResource.create(this.order);
	}
	removeItem(event:any) {
		var productId = event.target.getAttribute('data-id');
		this.$appStore.commit('removeItem', productId);
		this.applyPricing();
	}
	onQuantityChange(event:any) {
		this.applyPricing();
	}
	get items() : CartItem[] {
		return this.$appStore.state.cart.items;
	}
	get actionsDisabled():boolean {
		return this.applyingPricing;
	}
	get dicountDisabled() : boolean {
		let enabled = !this.applyingPricing &&
			this.items && this.items.length >= 1 &&
			this.discountCode && this.discountCode != ''
		return !enabled;
	}
	get discountText() : string {
		let message = ''
		if (this.order.appliedDiscount) {
			let discount = this.order.appliedDiscount;
			if (discount.type == 'PERCENTAGE') {
				message += discount.amount + '%';
			} else {
				message += '$' + discount.amount;
			}
			if (discount.code) {
				message += ' off for entering code: ' + discount.code;
			} else {
				message += ' off for spending over $' + discount.minOrderAmount;
			}
		}
		return message;
	}
}
