import { Resource } from '@api/core'
import Promotion from '../model/Promotion'
import { AxiosInstance } from 'axios';

export default class extends Resource<Promotion> {
	basePath = '/retail'
	resourceName = '/v1/promotions'

	constructor(client?: AxiosInstance) {
		super(client);
	}
}