


























import Vue from 'vue'
import Component from 'vue-class-component'
import ImageMixin from '../../../mixin/image'

@Component({
	mixins: [ImageMixin],
	props: ['images'],
	data: function () {
		return {
			selected: null,
			activeImageIndex: 0
		}
	}
})
export default class ImageGroup extends Vue {
	images?:string[];
	selected?:string;
	activeImageIndex:number = 0;

	get selectedImage() {
		if (!this.selected) {
			return this.images ? this.images[0] : '';
		}
		return this.selected;
	}
	selectImage(image:string, index:number) : void {
		this.selected = image;
		this.activeImageIndex = index;
	}
}
Vue.component('ImageGroup', ImageGroup);
