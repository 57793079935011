import { Resource } from '@api/core'
import User from '../model/User'
import { AxiosInstance } from 'axios';

export default class extends Resource<User> {
	basePath = 'user/'
	resourceName = 'v1/users'

	constructor(client?: AxiosInstance) {
		super(client);
	}
}