import { Resource } from '@api/core'
import Discount from '../model/Discount'
import { AxiosInstance } from 'axios';

export default class extends Resource<Discount> {
	basePath = '/retail'
	resourceName = '/v1/discounts'

	constructor(client?: AxiosInstance) {
		super(client);
	}
}