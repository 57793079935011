export interface PaymentState {
	payment: any;
}

export default {
	state: {
		payment: 0
	},
	mutations: {
		payment(state:PaymentState, payment:any) {
			state.payment = payment;
		}
	}
}