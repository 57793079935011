import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'
import Home from '../vue/home.vue'
import About from '../vue/about.vue'
import FAQ from '../vue/faq.vue'
import Policy from '../vue/policy.vue'
import ProductList from '../vue/product/product-list.vue'
import Cart from '../vue/cart.vue'
import ProductDetail from '../vue/product/product-detail.vue'
import SaleComplete from '../vue/paypal/complete.vue'

Vue.use(Router)

export default new Router({
	routes: [
		{ path: '/', component: Home },
		{ path: '/about', component: About },
		{ path: '/policy', component: Policy },
		{
			path: '/accessories',
			component: ProductList,
			props: { categoryIds: [1] },
			meta: { key: 1 }
		},
		{
			path: '/new',
			component: ProductList,
			props: { categoryIds: [6] },
			meta: { key: 6 }
		},
		{
			path: '/bottoms',
			component: ProductList,
			props: { categoryIds: [2] },
			meta: { key: 2 }
		},
		{
			path: '/tops',
			component: ProductList,
			props: { categoryIds: [3] },
			meta: { key: 3 }
		},
		{
			path: '/sleepwear',
			component: ProductList,
			props: { categoryIds: [7] },
			meta: { key: 7 }
		},
		{
			path: '/kids',
			component: ProductList,
			props: { categoryIds: [4] },
			meta: { key: 4 }
		},
		{
			path: '/dresses',
			component: ProductList,
			props: { categoryIds: [5] },
			meta: { key: 5 }
		},
		{
			path: '/sale',
			component: ProductList,
			props: { onSale: true },
			meta: { key: 1000 }
		},
		{ path: '/cart', component: Cart },
		{
			path: '/products/:id',
			name: 'product-details',
			component: ProductDetail,
			props: true
		},
		{ path: '/sale/complete', component: SaleComplete, props: true }
	]
})