import { render, staticRenderFns } from "./product-detail.vue?vue&type=template&id=51ff86aa&scoped=true&"
import script from "./product-detail.vue?vue&type=script&lang=ts&"
export * from "./product-detail.vue?vue&type=script&lang=ts&"
import style0 from "./product-detail.vue?vue&type=style&index=0&id=51ff86aa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51ff86aa",
  null
  
)

export default component.exports