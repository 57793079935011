




































import Vue from 'vue'
import Component from 'vue-class-component'
import ScrollMixin from '../../mixin/scroll'
import UserEmail from './user/email.vue'

@Component({
	mixins: [ScrollMixin],
	data() {
		return {
			email: 'mailto:sweetjuneboutique@gmail.com'
		}
	},
	components: {
		'user-email': UserEmail
	}
})
export default class Footer extends Vue {
	email:string;
	contact() {
		window.document.location.href = this.email;
	}
}
