
































import Vue from 'vue'
import Component from 'vue-class-component'
import { PaypalOrder, PaypalShipping, PaypalPayment } from '@api/paypal';

@Component
export default class SaleComplete extends Vue {
	success:boolean = false;
	payment:PaypalPayment = {
		id: '',
		amount: { currency_code: 'USD', value: '0' },
		status: ''
	};
	order:PaypalOrder = { id: '', purchase_units: [] };
	shipping:PaypalShipping = {} as PaypalShipping;
	mounted() {
		this.parseResponse();
	}
	parseResponse() {
		if (this.$appStore.state.order.paypalOrder) {
			const paypalOrder:PaypalOrder = this.$appStore.state.order.paypalOrder;
			const payment = paypalOrder.purchase_units[0].payments.captures[0];
			this.success = payment.status === 'COMPLETED';
			this.payment = payment;
			this.order = paypalOrder;
			this.shipping = paypalOrder.purchase_units[0].shipping;
		}
	}
	get shippingDetails() {
		var details = '';
		if (this.shipping) {
			details = this.shipping.name.full_name;
			details += '<br>';
			details += this.shipping.address.address_line_1;
			if (this.shipping.address.address_line_2) {
				details += this.shipping.address.address_line_2;
			}
			details += '<br>';
			details += this.shipping.address.admin_area_2;
			details += ', ';
			details += this.shipping.address.admin_area_1;
			details += ' ';
			details += this.shipping.address.postal_code;
		}
		return details;
	}
}
