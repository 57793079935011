import { render, staticRenderFns } from "./home.vue?vue&type=template&id=574ae278&scoped=true&"
import script from "./home.vue?vue&type=script&lang=ts&"
export * from "./home.vue?vue&type=script&lang=ts&"
import style0 from "./home.vue?vue&type=style&index=0&id=574ae278&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "574ae278",
  null
  
)

export default component.exports