import { CartItem } from '@api/retail'

export interface CartState {
	items: CartItem[];
}

export default {
	state: {
		items: []
	},
	mutations: {
		setItems(state:CartState, items:CartItem[]) {
			if (items) {
				state.items = items;
				sessionStorage.cartItems = JSON.stringify(state.items);
			}
		},
		addItem(state:CartState, item:CartItem) {
			const existingItem = state.items.find((i:CartItem) => i.productId === item.productId
                    && i.option === item.option);
			if (!existingItem) {
				state.items.push(item);
			}
		},
		removeItem(state:CartState, id:string) {
			const pos = state.items.map(function (i:CartItem) {
				return i.productId
			}).indexOf(parseInt(id, 10));
			if (pos > -1) {
				state.items.splice(pos, 1);
			}
		}
	}
}