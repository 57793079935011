const convertToSize = (value: string, size: string) => {
	let result = value;
	if (value) {
		const first = value.substring(0, value.lastIndexOf("."));
		const last = value.substring(value.lastIndexOf("."));
		result = `${first}-${size}${last}`;
	}
	return result;
}
export default {
	filters: {
		xs: (value: string) => convertToSize(value, "xs"),
		s: (value: string) => convertToSize(value, "s"),
		m: (value: string) => convertToSize(value, "m"),
		l: (value: string) => convertToSize(value, "l"),
		xl: (value: string) => convertToSize(value, "xl")
	}
}