


































































import Vue from 'vue'
import Component from 'vue-class-component'
import { facebookLink, instagramLink, imageBaseUrl } from '../constants'

@Component
export default class About extends Vue {
	photo = `${imageBaseUrl}/static/about_claudia.jpg`;
	signPhoto = `${imageBaseUrl}/static/about_sign.jpg`;
	photoLink = 'https://geminiphotography.instaproofs.com/gallery/#events';
	signLink = 'https://alightcustom.com/';
	facebookLink = facebookLink;
	instagramLink = instagramLink;
}
