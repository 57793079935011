







import {
	PaypalOrder,
	PaypalOrderItem, PaypalPaymentsCapture, PaypalPurchaseUnit,
	PaypalShipping, PaypalShippingChangeData
} from '@api/paypal'
import {
	CartItem, Order,
	OrderResource
} from '@api/retail'
import Axios, { AxiosResponse } from 'axios'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'


declare let paypal_sdk: any;

@Component({
	props: ['order', 'disabled', 'discountCode']
})
export default class PaypalButton extends Vue {
	@Prop() order: Order;
	@Prop() discountCode: string;
	@Prop() disabled: boolean;
	finalOrder: Order;
	orderResource: OrderResource = new OrderResource();

	mounted() {
		this.loadPaypal();
	}

	getPaypalPurchaseUnit(order: Order): PaypalPurchaseUnit {
		return {
			amount: {
				value: `${this.getTotalPrice(order)}`,
				currency_code: 'USD',
				breakdown: {
					item_total: {
						value: `${this.getSubtotal(order)}`,
						currency_code: 'USD'
					},
					shipping: {
						value: `${this.getShipping(order)}`,
						currency_code: 'USD'
					},
					tax_total: {
						value: `${this.getTotalTax(order)}`,
						currency_code: 'USD'
					}
				}
			},
			items: this.getPaypalItems(order),
			shipping: {} as PaypalShipping,
			payments: {} as PaypalPaymentsCapture
		};
	}

	getPaypalItems(order: Order): PaypalOrderItem[] {
		var i = 0;
		var item;
		var items = order.items.map((item: CartItem): PaypalOrderItem => ({
			sku: item.productId,
			name: item.name,
			quantity: item.quantity,
			description: item.option,
			unit_amount: {
				currency_code: 'USD',
				value: `${this.getItemPrice(item)}`
			},
			tax: {
				currency_code: 'USD',
				value: `${item.tax}`
			}
		}));
		return items;
	}
	getItemPrice(item: CartItem): number {
		const price = item.discount ? item.currentPrice - item.discount : item.currentPrice;
		return +(price.toFixed(2));
	}
	getSubtotal(order: Order): number {
		const subtotal = order.total - order.tax - order.shipping;
		return +(subtotal.toFixed(2));
	}
	getTotalPrice(order: Order): number {
		const total = order.total;
		return +(total.toFixed(2));
	}
	getTotalTax(order: Order): number {
		const tax = order.tax;
		return +(tax.toFixed(2));
	}
	getShipping(order: Order): number {
		const shipping = order.shipping;
		return +(shipping.toFixed(2));
	}
	loadPaypal() {
		Axios.get('retail/v1/settings/paypal').then((response: AxiosResponse) => response.data.clientId).then((paypalClientId) => {
			const script = document.createElement('script');
			script.id = 'paypal';
			script.setAttribute('data-namespace', 'paypal_sdk');
			script.src = `https://www.paypal.com/sdk/js?client-id=${paypalClientId}`;
			document.body.appendChild(script);
			script.onload = () => {
				this.renderPaypalButton();
			}
		}).catch((reason) => {});
	}
	renderPaypalButton() {
		paypal_sdk.Buttons({
			style: {
				label: 'pay',
				color: 'blue',
				shape: 'rect'
			},
			createOrder: (data: any, actions: any) => {
				this.finalOrder = this.order;
				return actions.order.create({
					purchase_units: [this.getPaypalPurchaseUnit(this.order)]
				});
			},
			onShippingChange: (data: PaypalShippingChangeData, actions: any) => {
				if (data.shipping_address.country_code !== 'US') {
					return actions.reject();
				}
				const cr = data.shipping_address.state === 'CO';
				const orderPricingRequest = {
					coloradoResident: cr,
					order: this.order,
					discountCode: this.discountCode
				};
				return this.orderResource.applyPricing(orderPricingRequest).then((orderPricingResponse) => {
					this.finalOrder = orderPricingResponse.order;
					return actions.order.patch([
						{
							op: 'replace',
							path: '/purchase_units/@reference_id==\'default\'',
							value: this.getPaypalPurchaseUnit(this.finalOrder)
						}
					]);
				})
			},
			onApprove: (data: any, actions: any) => actions.order.capture().then((paypalOrder: PaypalOrder) => {
				this.$emit('complete', this.finalOrder, paypalOrder);
			})
		}).render('#paypal-button');
	}
}
