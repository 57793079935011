


































import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
	data() {
		return {
			email: 'mailto:sweetjuneboutique@gmail.com'
		}
	}
})
export default class Policy extends Vue {
	email:string;
	contact() {
		window.document.location.href = this.email;
	}
}
