import Axios, { AxiosInstance } from 'axios';
import * as QS from 'qs';
import PageRequest from './model/PageRequest';
import PageResponse from './model/PageResponse';


export default class Resource<T> {
	protected resourceName:string = '';
	protected basePath:string = '';
	protected client:AxiosInstance;

	constructor(client?: AxiosInstance) {
		this.client = client || Axios.create();
	}

	async findOne(id:number):Promise<T> {
		const response = await this.client.get<T>(this.basePath + this.resourceName + '/' + id);
		return response.data;
	}
	async findAll(request?:PageRequest):Promise<PageResponse<T>> {
		const params = QS.parse(QS.stringify(request, {
			arrayFormat: 'repeat'
		}));
		const response = await this.client.get<PageResponse<T>>(this.basePath + this.resourceName, {
			params: params
		});
		return response.data;
	}
	async update(id:number, request:T):Promise<T> {
		const response = await this.client.put<T>(this.basePath + this.resourceName + '/' + id, request);
		return response.data;
	}
	async create(request:T):Promise<T> {
		const response = await this.client.post<T>(this.basePath + this.resourceName, request);
		return response.data;
	}
	async delete(id:number):Promise<T> {
		const response = await this.client.delete(this.basePath + this.resourceName + '/' + id);
		return response.data;
	}
	save(model:T):Promise<T> {
		if ('id' in model) {
			const id = (<any>model)['id'];
			if (id) {
				return this.update(id, model);
			}
		}
		return this.create(model);
	}
}


// export const find = <T>(url: string, request?: PageRequest): Promise<PageResponse<T>> => {
//     const params = { page: 1, size: 1000, ...request }
//     return axios.get<PageResponse<T>>(url, { params }).then((response => response.data));
// }

// export const update = <T>(url: string, payload: T): Promise<T> => {
//     return axios.put<T>(`${url}/${(<any>payload).id}`, payload).then((response) => response.data);
// }

// export const create = <T>(url: string, payload: T): Promise<T> => {
//     return axios.post<T>(url, payload).then((response) => response.data);
// }

// export const deleteOne = <T>(url: string, id: number): Promise<T> => {
//     return axios.delete(`${url}/${id}`).then((response) => response.data);
// }

// export const getOne = <T>(url: string, id: number): Promise<T> => {
//     return axios.get<T>(`${url}/${id}`).then((response) => response.data);
// }

// export const save = <T>(url: string, payload: T): Promise<T> => {
//     const id = (<any>payload).id;
//     if (id) {
//         return update(url, payload);
//     }
//     return create(url, payload);
// }