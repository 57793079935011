





















































import Vue from 'vue'
import Component from 'vue-class-component'
import { Product, ProductRequest, ProductResource } from '@api/retail'
import FormatMixin from '../../../mixin/format'
import ImageMixin from '../../../mixin/image'

@Component({
	mixins: [FormatMixin, ImageMixin],
	props: ['categoryIds', 'onSale']
})
export default class ProductList extends Vue {
	categoryIds:Array<number>;
	onSale:boolean;
	items:Array<Product> = [];
	productResource:ProductResource = new ProductResource();

	mounted() {
		this.load()
	}
	async load() {
		this.items = [];
		const request: ProductRequest = {
			categoryIds: this.categoryIds,
			onSale: this.onSale,
			active: true,
			inStock: true,
			page: 1,
			size: 10
		};
		let more = true;
		let start:number;
		while (more) {
			await this.productResource.findAll(request).then((response) => {
				if (!start) {
					start = Date.now();
				}
				response.results.forEach((product, index) => {
					let now = Date.now();
					let overallIndex = ((request.page - 1) * request.size) + index;
					let timeSinceFirst = now - start;
					let delay = overallIndex * 25 - timeSinceFirst;
					setTimeout(() => {
						this.items.push(product);
					}, delay);
				})
				more = response.more;
			}, () => {
				more = false;
			});
			request.page++;
		}
	}

	isOutOfStock(product:Product) : boolean {
		return product.options.every(option => option.quantity == 0);
	}
}
