









































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { facebookLink, instagramLink, imageBaseUrl } from '../constants'

@Component
export default class Header extends Vue {
	logo = `${imageBaseUrl}/static/header_logo.png`;
	facebookLink = facebookLink;
	instagramLink = instagramLink;
}
